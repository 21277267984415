import React, { useState, useEffect } from "react";
import { useAuth } from "../../Context/auth";
import axios from "axios";
import Layout from "./../../Componet/Layout/Layout";
import logo from "../../Assets/sitelogo.png";
import toast from "react-hot-toast";
import moment from "moment";
import backgroundImage from "../../Assets/20255.jpg";
import bellIcon from "../../Assets/bell.png";

import "../../index.css";

import {
  FaFastBackward,
  FaPlaystation,
  FaWhatsapp,
  FaCheckCircle,
  FaDollarSign,
  FaMoneyBillWave,
  FaUserFriends,
  FaEye,
  FaChartLine,
  FaAd,
  FaUsers,
  FaUserCheck,
  FaUserSlash,
  FaBoxOpen,
  FaArrowDown,
  FaMoneyBillAlt,
  FaWallet,
  FaPiggyBank,
  FaMoneyCheck,
  FaCoins,
  FaStore,
  FaSchool,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
const UserDashboard = () => {
  const [auth] = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [earnings, setEarnings] = useState(null);
  const [membershipDetails, setMembershipDetails] = useState(null);
  const [activeUsers, setActiveUsers] = useState(0);
  const [unreadCount, setUnreadCount] = useState(0);
  const [selectedMessage, setSelectedMessage] = useState(null);

  const [inactiveUsers, setInactiveUsers] = useState(0);
  const [referralCode, setReferralCode] = useState("");
  const [referralLink, setReferralLink] = useState("");
  const [copyTooltipText, setCopyTooltipText] = useState("Copy");
  const [totalReferrals, setTotalReferrals] = useState(0);
  const [referrals, setReferrals] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [heading, setHeading] = useState();
  const [notification, setNotification] = useState("");
  const [titles, setTitles] = useState([]);
  const [links, setlinks] = useState();
  const [contacts, setContacts] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalAdsViewed, setTotalAdsViewed] = useState(0);
  const [remainingAds, setRemainingAds] = useState(0);
  const [nextAvailableTime, setNextAvailableTime] = useState(null);
  const [showTimer, setShowTimer] = useState(false);
  const [message, setMessage] = useState("");
  const [userData, setUserData] = useState(null); // To store user and level info
  const [isClaimed, setIsClaimed] = useState(false); // To track if salary is claimed
  const [timer, setTimer] = useState(0); // To store the remaining time for next claim
  const [isEligible, setIsEligible] = useState(false); // To track salary eligibility
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [Claiming] = useState(false);
  const [link, setLink] = useState();

  const [salaryClaims, setSalaryClaims] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);

  // State for unread notifications and notifications list
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [userdata, setUserdata] = useState(null);
  // Define the currencySymbol function before using it
  const currencySymbol = () => {
    if (!earnings?.currency || earnings.currency === "") {
      return "Rs"; // Default to Rs if currency is null, undefined, or empty
    } else if (earnings.currency === "PKR") {
      return "Rs";
    } else if (earnings.currency === "USD") {
      return "$";
    } else {
      return ""; // Default case for unsupported currencies
    }
  };

  const displayCurrency = currencySymbol(); // Now call currencySymbol after its definition

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchMembershipDetails = async () => {
      try {
        const response = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/purchase/membership"
        );
        setMembershipDetails(response.data);
      } catch (err) {
        setError("Failed to fetch membership details.");
      } finally {
        setLoading(false);
      }
    };

    fetchMembershipDetails();
  }, []);

  useEffect(() => {
    const fetchUserEarnings = async () => {
      try {
        const response = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/users/earnings",
          {}
        );
        setEarnings(response.data);
      } catch (err) {
        setError("Failed to fetch earnings.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserEarnings();
  }, []);
  // useEffect(() => {
  // const fetchUserReferrals = async () => {
  // try {
  // const response = await axios.get(
  //   "https://backend-code-9qn4.onrender.com/api/v1/users/total-referrals"
  // );
  // setTotalReferrals(response.data.totalReferrals);
  // setReferrals(response.data.referralDetails);

  // // Calculate active and inactive users
  // const activeCount = response.data.referralDetails.filter(
  //   (referral) => referral.packageStatus === "Active"
  // ).length;
  // const inactiveCount =
  //   response.data.referralDetails.length - activeCount;

  // setActiveUsers(activeCount);
  // setInactiveUsers(inactiveCount);
  // } catch (err) {
  // setError("Failed to fetch referrals.");
  // }
  // };
  // fetchUserReferrals();
  // }, []);
  // const handleCopyReferralCode = () => {
  //   navigator.clipboard.writeText(referralCode).then(() => {
  //     setCopyTooltipText("Copied!");
  //     setTimeout(() => {
  //       setCopyTooltipText("Copy");
  //     }, 2000);
  //   });
  // };

  const handleCopyreferralLink = () => {
    // Create a temporary input element to hold the full text to copy
    const fullText = `${referralLink} 
    `;
    const tempInput = document.createElement("input");
    tempInput.value = fullText;

    // Append the temporary input to the body
    document.body.appendChild(tempInput);

    // Select the content of the temporary input
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected content
    document.execCommand("copy");

    // Remove the temporary input from the document
    document.body.removeChild(tempInput);

    // Update the tooltip text to indicate that the content was copied
    setCopyTooltipText("Copied!");

    // Optionally, you can reset the tooltip text after a few seconds
    setTimeout(() => setCopyTooltipText("Copy"), 2000);
  };

  // useEffect(() => {
  //   const fetchReferralCode = async () => {
  //     try {
  //       const response = await axios.get(
  //         "https://backend-code-9qn4.onrender.com/api/v1/users/get-refferalcode"
  //       );
  //       setReferralCode(response.data.referralCode);
  //     } catch (err) {
  //       setError("Failed to fetch referral code.");
  //     }
  //   };
  //   fetchReferralCode();
  // }, []);
  useEffect(() => {
    const fetchReferralLink = async () => {
      try {
        const response = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/users/get-refferallink"
        );
        setReferralLink(response.data.referralLink);
      } catch (err) {
        setError("Failed to fetch referral code.");
      }
    };
    fetchReferralLink();
  }, []);
  // useEffect(() => {
  //   const fetchWithdrawals = async () => {
  //     try {
  //       const response = await axios.get(
  //         "https://backend-code-9qn4.onrender.com/api/v1/userwithdrawal/get-all-single-withdrawal",
  //         {}
  //       );
  //       console.log(response.data); // Log the response data

  //       // Ensure response.data.withdrawals is defined
  //       if (response.data && response.data.withdrawals) {
  //         // Sort the withdrawals by createdAt date in descending order
  //         const sortedWithdrawals = response.data.withdrawals.sort(
  //           (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  //         );
  //         setWithdrawals(sortedWithdrawals);
  //       } else {
  //         setError("No withdrawals found");
  //       }
  //     } catch (error) {
  //       setError("");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchWithdrawals();
  // }, [auth.token]);
  // Get all Notifications

  // Get all Notifications
  const getAllTitles = async () => {
    try {
      const { data } = await axios.get(
        "https://backend-code-9qn4.onrender.com/api/v1/notifiication/get-notify"
      );
      // Ensure data.notifications is an array of objects with a 'notification' string property
      if (Array.isArray(data.announcements)) {
        setTitles(data.announcements);
      } else {
        toast.error("Unexpected data format");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  // Lifecycle method
  useEffect(() => {
    getAllTitles();
  }, []);

  const fetchUserStats = async () => {
    try {
      const earningsResponse = await axios.get(
        "https://backend-code-9qn4.onrender.com/api/v1/ads/user-ads-earnings"
      );
      setTotalEarnings(earningsResponse.data.totalEarnings);

      const statsResponse = await axios.get(
        "https://backend-code-9qn4.onrender.com/api/v1/ads/user-total-ads-viewed"
      );
      const { totalAdsViewed, remainingAdsToday } = statsResponse.data;
      setTotalAdsViewed(totalAdsViewed);
      setRemainingAds(remainingAdsToday);

      // Calculate next available time
      const now = moment();
      const nextAdTime = now.add(24, "hours").startOf("day").toDate();
      setNextAvailableTime(nextAdTime);

      if (remainingAdsToday <= 0) {
        setMessage(
          "You have viewed all ads for today. Please come back tomorrow."
        );
        setShowTimer(false);
      }
    } catch (error) {
      console.error("Error fetching user stats:", error);
    }
  };
  useEffect(() => {
    fetchUserStats();
  }, []);
  const formatAmount = (amount) => {
    return parseFloat(amount)
      .toFixed(4)
      .replace(/(\.0+|(?<=\.\d)0+)$/, "");
  };

  //next page logic
  // const [currentPage, setCurrentPage] = useState(1);
  // const referralsPerPage = 10;

  // const totalPages = Math.ceil(referrals.length / referralsPerPage);
  // const startIndex = (currentPage - 1) * referralsPerPage;
  // const currentReferrals = referrals.slice(
  //   startIndex,
  //   startIndex + referralsPerPage
  // );

  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  // };

  // const rowsPerPage = 10;

  // Pagination Logic
  // const totalPage = Math.ceil(withdrawals.length / rowsPerPage);
  // const currentWithdrawals = withdrawals.slice(
  //   (currentPage - 1) * rowsPerPage,
  //   currentPage * rowsPerPage
  // );
  //salary
  useEffect(() => {
    const fetchSalaryDetails = async () => {
      try {
        const response = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/leveluser/getsalary"
        );
        const { message, monthlySalary, salarydays, MinActiveRef } =
          response.data;

        if (message === "Eligible for salary") {
          setUserData({ monthlySalary, salarydays, MinActiveRef });
          setIsEligible(true);
        } else {
          setIsEligible(false);
          setError(message);
        }
      } catch (err) {
        setError("Unable to fetch salary details.");
      }
    };

    fetchSalaryDetails();
  }, []);

  const handleClaim = async () => {
    if (activeUsers < userData.MinActiveRef) {
      setError("Not enough active referrals to claim salary.");
      return;
    }

    try {
      const response = await axios.post(
        "https://backend-code-9qn4.onrender.com/api/v1/leveluser/claim-salary"
      );
      const { message } = response.data;

      if (message === "Salary claimed successfully.") {
        setIsClaimed(true);
        setTimeout(() => {
          setIsClaimed(false); // Reset after time elapses
        }, userData.salarydays * 24 * 60 * 60 * 1000);
      }
    } catch (err) {
      setError("Unable to claim salary at this time.");
    }
  };

  // useEffect(() => {
  //   const fetchUserReferrals = async () => {
  //     try {
  //       const response = await axios.get(
  //         "https://backend-code-9qn4.onrender.com/api/v1/users/total-referrals"
  //       );
  //       const activeCount = response.data.referralDetails.filter(
  //         (ref) => ref.packageStatus === "Active"
  //       ).length;

  //       setActiveUsers(activeCount);
  //     } catch (err) {
  //       setError("Failed to fetch referral details.");
  //     }
  //   };

  //   fetchUserReferrals();
  // }, []);

  useEffect(() => {
    const fetchSalaryDetails = async () => {
      try {
        const response = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/leveluser/user/salary-details"
        );
        const { user, salaryClaims, message } = response.data;

        setUserdata(user);
        setSalaryClaims(salaryClaims);
      } catch (err) {
        setError("Unable to fetch salary details.");
      }
    };

    fetchSalaryDetails();
  }, []);

  // Fetch the user's message on component mount
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/message/get-message"
        );
        if (response.data.success) {
          setNotifications(response.data.data);
          setUnreadCount(response.data.data.length);
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };
    fetchMessages();
  }, []);

  const handleBellClick = () => {
    setShowNotifications(!showNotifications);
    setUnreadCount(0);
  };

  const handleMessageClick = (message) => {
    setSelectedMessage(message);
  };

  return (
    <Layout title={"User-Dashboard EᵃʳⁿTᵘᵇᵉ💲"}>
      <div
        style={{
          padding: "40px",
          borderRadius: "20px",
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.5)",
          textAlign: "center",
          maxWidth: "750px",
          margin: "100px auto",
          position: "relative",
          overflow: "hidden",
          color: "white",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          style={{
            width: "80px",
            height: "40px",
            backgroundColor: "#FFC107",
            borderRadius: "10%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            position: "absolute",
            top: "10px",
            right: "20px",
            cursor: "pointer",
          }}
          onClick={handleBellClick}
        >
          <img
            src={bellIcon}
            alt="Notifications"
            style={{ width: "40px", height: "40px" }}
          />
          {unreadCount > 0 && (
            <span
              style={{
                position: "absolute",
                top: "-5px",
                right: "-5px",
                backgroundColor: "red",
                color: "white",
                borderRadius: "50%",
                padding: "5px 8px",
                fontSize: "12px",
              }}
            >
              {unreadCount}
            </span>
          )}
        </div>

        {showNotifications && (
          <div
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              backgroundColor: "rgba(0, 0, 0, 0.9)",
              zIndex: "10",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => setShowNotifications(false)}
          >
            <div
              style={{
                backgroundColor: "#000",
                borderRadius: "10px",
                padding: "20px",
                width: "400px",
                boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
                overflowY: "auto",
                maxHeight: "70vh",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <h3 style={{ marginBottom: "15px", color: "#FFD700" }}>
                Notifications
              </h3>
              {notifications.map((notif, index) => (
                <div
                  key={index}
                  style={{
                    padding: "10px",
                    marginBottom: "10px",
                    borderBottom: "1px solid #444",
                    cursor: "pointer",
                    color: "#FFD700",
                  }}
                  onClick={() => handleMessageClick(notif.message)}
                >
                  <p>{notif.message.slice(0, 20)}...</p>
                  <small style={{ color: "#999" }}>
                    {new Date(notif.createdAt).toLocaleString()}
                  </small>
                </div>
              ))}
            </div>
          </div>
        )}

        {selectedMessage && (
          <div
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              zIndex: "20",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => setSelectedMessage(null)}
          >
            <div
              style={{
                backgroundColor: "#222",
                borderRadius: "10px",
                padding: "20px",
                width: "350px",
                textAlign: "center",
                color: "#FFD700",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <p>{selectedMessage}</p>
              <button
                onClick={() => setSelectedMessage(null)}
                style={{
                  marginTop: "20px",
                  padding: "10px 20px",
                  border: "none",
                  backgroundColor: "#FFD700",
                  color: "#000",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}

        <div style={{ position: "relative", zIndex: "2" }}>
          <h1
            style={{
              fontSize: "42px",
              fontWeight: "700",
              textTransform: "uppercase",
              color: "black",
              marginBottom: "20px",
            }}
          >
            DASHBOARD
          </h1>

          <div className="user-info" style={{ marginTop: "30px" }}>
            <img
              src={logo}
              alt="Dashboard Logo"
              className="responsive-logo"
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                border: "4px solid #00ffff",
                boxShadow: "0px 5px 20px rgba(0, 255, 255, 0.5)",
              }}
            />

            <div
              className="info-box"
              style={{
                marginTop: "20px",
                padding: "20px",
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: "15px",
                boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  marginBottom: "10px",
                  fontWeight: "600",
                  color: "white",
                }}
              >
                👨 {auth?.user?.username}
              </p>
              <p style={{ fontSize: "18px", marginBottom: "5px" }}>
                <strong
                  style={{
                    color: "#fff",
                    padding: "10px",
                  }}
                >
                  Earnings:
                </strong>{" "}
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span
                    style={{
                      color: "#fff",
                      padding: "10px",
                    }}
                  >
                    {formatAmount(earnings ? earnings.earnings : "0")}{" "}
                    {displayCurrency}
                  </span>
                )}
              </p>
              <p style={{ fontSize: "18px" }}>
                <strong
                  style={{
                    color: "#fff",
                    padding: "10px",
                  }}
                >
                  Total Earnings:
                </strong>{" "}
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span
                    style={{
                      color: "#fff",
                      padding: "10px",
                    }}
                  >
                    {formatAmount(earnings ? earnings.totalEarnings : "0")}{" "}
                    {displayCurrency}
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12" style={{ marginTop: "20px" }}>
        <div className="row justify-content-center">
          <div className="package-list">
            {titles.length === 0 ? (
              <div className="alert alert-secondary text-center" role="alert">
                <h2>No Notifications</h2>
              </div>
            ) : (
              titles.map((p) => (
                <div
                  key={p._id}
                  className="notification-item alert alert-success"
                >
                  <h2
                    style={{ color: "black" }}
                    className="notification-heading"
                  >
                    {p.heading}...
                  </h2>
                  <p className="notification-content">{p.notification}</p>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      {/*  <div className="join-community">
        <NavLink to="/dashboard/user/contact-user" className="community-link">
          <FaWhatsapp className="whatsapp-icon" />
          <span style={{ color: "black" }}>
            Join Our Community for Every Notification
          </span>
        </NavLink>
      </div>*/}
      {/* <div>
        <h2
          style={{
            textAlign: "center",
            color: "lightblue",
            fontWeight: "bold",
            fontSize: "30px",
          }}
        >
          How to buy free package full video
        </h2>
        <div className="ratio ratio-4x3" style={{ marginBottom: "-500" }}>
          <iframe
            id="youtube-iframe"
            src="https://www.youtube.com/embed/tfjy2rjSxjQ?autoplay=1&mute=1&loop=1&playlist=tfjy2rjSxjQ"
            title="YouTube video"
            allowFullScreen
            style={{ width: "100%", height: "50%" }}
          ></iframe>
        </div>qEVccF
      </div>*/}
      <div
        style={{
          background: "brown",
          color: "black",
          fontWeight: "bold",
          padding: "25px",
          borderRadius: "10px",
          textAlign: "center",
          width: "150px",
          height: "100px",
          cursor: "pointer",
          boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
        }}
        onClick={() => (window.location.href = "/dashboard/user/comingsoon")}
      >
        <FaStore />
        EarnTube Store
      </div>
      <div
        style={{
          background: "brown",
          color: "black",
          fontWeight: "bold",
          padding: "25px",
          borderRadius: "10px",
          textAlign: "center",
          width: "150px",
          height: "100px",
          cursor: "pointer",
          boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
          marginLeft: 200,
          marginTop: -100,
        }}
        onClick={() => (window.location.href = "/dashboard/user/comingsoon")}
      >
        <FaSchool />
        Educator Academy
      </div>
      <div className="join-community">
        <a
          href="" // Replace with your actual WhatsApp group link
          target="_blank" // This will open the link in a new tab
          rel="noopener noreferrer" // For security reasons
          className="community-link"
        >
          <FaCoins className="coin-icon" />
          <span style={{ color: "black" }}>CoinApp Coming Soon</span>
        </a>
      </div>
      <div className="join-community">
        <a
          href="https://whatsapp.com/channel/0029Vb8dh8qEVccFoneU6P0Y" // Replace with your actual WhatsApp group link
          target="_blank" // This will open the link in a new tab
          rel="noopener noreferrer" // For security reasons
          className="community-link"
        >
          <FaWhatsapp className="whatsapp-icon" />
          <span style={{ color: "black" }}>
            Please join the channel for all offical update
          </span>
        </a>
      </div>

      {/* <div className="join-community">
        <NavLink to="" className="community-link">
          <FaPlaystation className="whatsapp-icon" />
          <span style={{ color: "black" }}>
            Our official EarnTube💲 app will be available soon on the Play
            Store. Stay tuned for the launch!
          </span>
        </NavLink>
      </div>*/}
      <div>
        {membershipDetails ? (
          <>
            <div className="d-flex flex-wrap justify-content-around">
              {/* Package Card */}
              <div
                className="card mt-5 p-3"
                style={{
                  width: "300px",
                  background: "linear-gradient(45deg, #ff7e5f, #feb47b)",
                  borderRadius: "20px",
                  boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
                  color: "white",
                  overflow: "hidden",
                  position: "relative",
                  textAlign: "center",
                  transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background 0.3s ease",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0 15px 35px rgba(0, 0, 0, 0.3)";
                  e.currentTarget.style.background =
                    "linear-gradient(45deg, #6a11cb, #2575fc)"; // Color change on hover
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0 10px 25px rgba(0, 0, 0, 0.2)";
                  e.currentTarget.style.background =
                    "linear-gradient(45deg, #ff7e5f, #feb47b)"; // Revert color
                }}
              >
                {/* Glowing Effect */}
                <div
                  style={{
                    position: "absolute",
                    top: "-20px",
                    left: "-20px",
                    width: "100%",
                    height: "100%",
                    background:
                      "radial-gradient(circle, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
                    filter: "blur(40px)",
                    zIndex: 1,
                    animation: "glow 1.5s infinite",
                  }}
                ></div>

                {/* Main Content */}
                <div style={{ position: "relative", zIndex: 2 }}>
                  <label
                    style={{
                      fontSize: "22px",
                      textAlign: "center",
                      display: "block",
                      color: "#fff",
                      marginBottom: "10px",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                  >
                    <FaBoxOpen
                      className="icon-animation"
                      style={{
                        fontSize: "28px",
                        color: "#ffffff",
                        marginRight: "8px",
                      }}
                    />{" "}
                    <strong>Package</strong>
                  </label>
                  <p
                    style={{
                      fontSize: "32px",
                      color: "#fff",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      textShadow: "2px 2px 10px rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    <strong>{membershipDetails.packageName}</strong>
                  </p>

                  {/* Arrow Icon */}
                </div>

                {/* Expandable Window Animation */}
                <div
                  className="window-effect"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    background: "#333",
                    borderRadius: "20px",
                    transition: "transform 0.4s ease-in-out",
                    transform: "scaleY(0)",
                    transformOrigin: "top",
                    overflow: "hidden",
                    zIndex: 1,
                  }}
                ></div>
              </div>

              {/* Status Card */}
              <div
                className="card mt-5 p-3"
                style={{
                  width: "300px",
                  background: "linear-gradient(45deg, #ff7e5f, #feb47b)",
                  borderRadius: "20px",
                  boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
                  color: "white",
                  overflow: "hidden",
                  position: "relative",
                  textAlign: "center",
                  transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background 0.3s ease",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0 15px 35px rgba(0, 0, 0, 0.3)";
                  e.currentTarget.style.background =
                    "linear-gradient(45deg, #6a11cb, #2575fc)"; // Color change on hover
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0 10px 25px rgba(0, 0, 0, 0.2)";
                  e.currentTarget.style.background =
                    "linear-gradient(45deg, #ff7e5f, #feb47b)"; // Revert color
                }}
              >
                {/* Glowing Effect */}
                <div
                  style={{
                    position: "absolute",
                    top: "-20px",
                    left: "-20px",
                    width: "100%",
                    height: "100%",
                    background:
                      "radial-gradient(circle, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
                    filter: "blur(40px)",
                    zIndex: 1,
                    animation: "glow 1.5s infinite",
                  }}
                ></div>

                {/* Main Content */}
                <div style={{ position: "relative", zIndex: 2 }}>
                  <label
                    style={{
                      fontSize: "22px",
                      textAlign: "center",
                      display: "block",
                      color: "#fff",
                      marginBottom: "10px",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                  >
                    <FaCheckCircle
                      className="icon-animation"
                      style={{
                        fontSize: "28px",
                        color: "#ffffff",
                        marginRight: "8px",
                      }}
                    />{" "}
                    <strong>Status</strong>
                  </label>
                  <p
                    style={{
                      fontSize: "32px",
                      color: "#fff",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      textShadow: "2px 2px 10px rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    <strong>{membershipDetails.packageStatus}</strong>
                  </p>

                  {/* Arrow Icon */}
                </div>

                {/* Expandable Window Animation */}
                <div
                  className="window-effect"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    background: "#333",
                    borderRadius: "20px",
                    transition: "transform 0.4s ease-in-out",
                    transform: "scaleY(0)",
                    transformOrigin: "top",
                    overflow: "hidden",
                    zIndex: 1,
                  }}
                ></div>
              </div>

              {/* Available Earnings Card */}
              <div
                className="card mt-5 p-3"
                style={{
                  width: "300px",
                  background: "linear-gradient(45deg, #ff7e5f, #feb47b)",
                  borderRadius: "20px",
                  boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
                  color: "white",
                  overflow: "hidden",
                  position: "relative",
                  textAlign: "center",
                  transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background 0.3s ease",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0 15px 35px rgba(0, 0, 0, 0.3)";
                  e.currentTarget.style.background =
                    "linear-gradient(45deg, #6a11cb, #2575fc)"; // Color change on hover
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0 10px 25px rgba(0, 0, 0, 0.2)";
                  e.currentTarget.style.background =
                    "linear-gradient(45deg, #ff7e5f, #feb47b)"; // Revert color
                }}
              >
                {/* Glowing Effect */}
                <div
                  style={{
                    position: "absolute",
                    top: "-20px",
                    left: "-20px",
                    width: "100%",
                    height: "100%",
                    background:
                      "radial-gradient(circle, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
                    filter: "blur(40px)",
                    zIndex: 1,
                    animation: "glow 1.5s infinite",
                  }}
                ></div>

                {/* Main Content */}
                <div style={{ position: "relative", zIndex: 2 }}>
                  <label
                    style={{
                      fontSize: "22px",
                      textAlign: "center",
                      display: "block",
                      color: "#fff",
                      marginBottom: "10px",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                  >
                    <FaDollarSign
                      className="icon-animation"
                      style={{
                        fontSize: "28px",
                        color: "#ffffff",
                        marginRight: "8px",
                      }}
                    />{" "}
                    <strong>Cash</strong>
                  </label>
                  <p
                    style={{
                      fontSize: "32px",
                      color: "#fff",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      textShadow: "2px 2px 10px rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    {formatAmount(earnings ? earnings.earnings : "0")}{" "}
                    {displayCurrency}
                  </p>

                  {/* Arrow Icon */}
                </div>

                {/* Expandable Window Animation */}
                <div
                  className="window-effect"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    background: "#333",
                    borderRadius: "20px",
                    transition: "transform 0.4s ease-in-out",
                    transform: "scaleY(0)",
                    transformOrigin: "top",
                    overflow: "hidden",
                    zIndex: 1,
                  }}
                ></div>
              </div>

              {/* Total Earnings Card */}
              <div
                className="card mt-5 p-3"
                style={{
                  width: "300px",
                  background: "linear-gradient(45deg, #ff7e5f, #feb47b)",
                  borderRadius: "20px",
                  boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
                  color: "white",
                  overflow: "hidden",
                  position: "relative",
                  textAlign: "center",
                  transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background 0.3s ease",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0 15px 35px rgba(0, 0, 0, 0.3)";
                  e.currentTarget.style.background =
                    "linear-gradient(45deg, #6a11cb, #2575fc)"; // Color change on hover
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0 10px 25px rgba(0, 0, 0, 0.2)";
                  e.currentTarget.style.background =
                    "linear-gradient(45deg, #ff7e5f, #feb47b)"; // Revert color
                }}
              >
                {/* Glowing Effect */}
                <div
                  style={{
                    position: "absolute",
                    top: "-20px",
                    left: "-20px",
                    width: "100%",
                    height: "100%",
                    background:
                      "radial-gradient(circle, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
                    filter: "blur(40px)",
                    zIndex: 1,
                    animation: "glow 1.5s infinite",
                  }}
                ></div>

                {/* Main Content */}
                <div style={{ position: "relative", zIndex: 2 }}>
                  <label
                    style={{
                      fontSize: "22px",
                      textAlign: "center",
                      display: "block",
                      color: "#fff",
                      marginBottom: "10px",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                  >
                    <FaMoneyBillWave
                      className="icon-animation"
                      style={{
                        fontSize: "28px",
                        color: "#ffffff",
                        marginRight: "8px",
                      }}
                    />{" "}
                    <strong>Total Credits</strong>
                  </label>
                  <p
                    style={{
                      fontSize: "32px",
                      color: "#fff",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      textShadow: "2px 2px 10px rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    {formatAmount(earnings ? earnings.totalEarnings : "0")}{" "}
                    {displayCurrency}
                  </p>

                  {/* Arrow Icon */}
                </div>

                {/* Expandable Window Animation */}
                <div
                  className="window-effect"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    background: "#333",
                    borderRadius: "20px",
                    transition: "transform 0.4s ease-in-out",
                    transform: "scaleY(0)",
                    transformOrigin: "top",
                    overflow: "hidden",
                    zIndex: 1,
                  }}
                ></div>
              </div>

              {/* Referrals Commission Card */}
              <div
                className="card mt-5 p-3"
                style={{
                  width: "300px",
                  background: "linear-gradient(45deg, #ff7e5f, #feb47b)",
                  borderRadius: "20px",
                  boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
                  color: "white",
                  overflow: "hidden",
                  position: "relative",
                  textAlign: "center",
                  transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background 0.3s ease",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0 15px 35px rgba(0, 0, 0, 0.3)";
                  e.currentTarget.style.background =
                    "linear-gradient(45deg, #6a11cb, #2575fc)"; // Color change on hover
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0 10px 25px rgba(0, 0, 0, 0.2)";
                  e.currentTarget.style.background =
                    "linear-gradient(45deg, #ff7e5f, #feb47b)"; // Revert color
                }}
              >
                {/* Glowing Effect */}
                <div
                  style={{
                    position: "absolute",
                    top: "-20px",
                    left: "-20px",
                    width: "100%",
                    height: "100%",
                    background:
                      "radial-gradient(circle, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
                    filter: "blur(40px)",
                    zIndex: 1,
                    animation: "glow 1.5s infinite",
                  }}
                ></div>

                {/* Main Content */}
                <div style={{ position: "relative", zIndex: 2 }}>
                  <label
                    style={{
                      fontSize: "22px",
                      textAlign: "center",
                      display: "block",
                      color: "#fff",
                      marginBottom: "10px",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                  >
                    <FaUserFriends
                      className="icon-animation"
                      style={{
                        fontSize: "28px",
                        color: "#ffffff",
                        marginRight: "8px",
                      }}
                    />{" "}
                    <strong>Refferal Bonus</strong>
                  </label>
                  <p
                    style={{
                      fontSize: "32px",
                      color: "#fff",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      textShadow: "2px 2px 10px rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    {formatAmount(earnings ? earnings.CommissionAmount : "0")}{" "}
                    {displayCurrency}
                  </p>

                  {/* Arrow Icon */}
                </div>

                {/* Expandable Window Animation */}
                <div
                  className="window-effect"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    background: "#333",
                    borderRadius: "20px",
                    transition: "transform 0.4s ease-in-out",
                    transform: "scaleY(0)",
                    transformOrigin: "top",
                    overflow: "hidden",
                    zIndex: 1,
                  }}
                ></div>
              </div>

              {/* Ads Watch Earnings Card */}
              <div
                className="card mt-5 p-3"
                style={{
                  width: "300px",
                  background: "linear-gradient(45deg, #ff7e5f, #feb47b)",
                  borderRadius: "20px",
                  boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
                  color: "white",
                  overflow: "hidden",
                  position: "relative",
                  textAlign: "center",
                  transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background 0.3s ease",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0 15px 35px rgba(0, 0, 0, 0.3)";
                  e.currentTarget.style.background =
                    "linear-gradient(45deg, #6a11cb, #2575fc)"; // Color change on hover
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0 10px 25px rgba(0, 0, 0, 0.2)";
                  e.currentTarget.style.background =
                    "linear-gradient(45deg, #ff7e5f, #feb47b)"; // Revert color
                }}
              >
                {/* Glowing Effect */}
                <div
                  style={{
                    position: "absolute",
                    top: "-20px",
                    left: "-20px",
                    width: "100%",
                    height: "100%",
                    background:
                      "radial-gradient(circle, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
                    filter: "blur(40px)",
                    zIndex: 1,
                    animation: "glow 1.5s infinite",
                  }}
                ></div>

                {/* Main Content */}
                <div style={{ position: "relative", zIndex: 2 }}>
                  <label
                    style={{
                      fontSize: "22px",
                      textAlign: "center",
                      display: "block",
                      color: "#fff",
                      marginBottom: "10px",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                  >
                    <FaEye
                      className="icon-animation"
                      style={{
                        fontSize: "28px",
                        color: "#ffffff",
                        marginRight: "8px",
                      }}
                    />{" "}
                    <strong>Ads Earnings</strong>
                  </label>
                  <p
                    style={{
                      fontSize: "32px",
                      color: "#fff",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      textShadow: "2px 2px 10px rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    {formatAmount(totalEarnings)} {displayCurrency}
                  </p>

                  {/* Arrow Icon */}
                </div>

                {/* Expandable Window Animation */}
                <div
                  className="window-effect"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    background: "#333",
                    borderRadius: "20px",
                    transition: "transform 0.4s ease-in-out",
                    transform: "scaleY(0)",
                    transformOrigin: "top",
                    overflow: "hidden",
                    zIndex: 1,
                  }}
                ></div>
              </div>

              {/* Total Ads Watch Card */}
              <div
                className="card mt-5 p-3"
                style={{
                  width: "300px",
                  background: "linear-gradient(45deg, #ff7e5f, #feb47b)",
                  borderRadius: "20px",
                  boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
                  color: "white",
                  overflow: "hidden",
                  position: "relative",
                  textAlign: "center",
                  transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background 0.3s ease",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0 15px 35px rgba(0, 0, 0, 0.3)";
                  e.currentTarget.style.background =
                    "linear-gradient(45deg, #6a11cb, #2575fc)"; // Color change on hover
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0 10px 25px rgba(0, 0, 0, 0.2)";
                  e.currentTarget.style.background =
                    "linear-gradient(45deg, #ff7e5f, #feb47b)"; // Revert color
                }}
              >
                {/* Glowing Effect */}
                <div
                  style={{
                    position: "absolute",
                    top: "-20px",
                    left: "-20px",
                    width: "100%",
                    height: "100%",
                    background:
                      "radial-gradient(circle, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
                    filter: "blur(40px)",
                    zIndex: 1,
                    animation: "glow 1.5s infinite",
                  }}
                ></div>

                {/* Main Content */}
                <div style={{ position: "relative", zIndex: 2 }}>
                  <label
                    style={{
                      fontSize: "22px",
                      textAlign: "center",
                      display: "block",
                      color: "#fff",
                      marginBottom: "10px",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                  >
                    <FaChartLine
                      className="icon-animation"
                      style={{
                        fontSize: "28px",
                        color: "#ffffff",
                        marginRight: "8px",
                      }}
                    />{" "}
                    <strong>Total Ads Watch</strong>
                  </label>
                  <p
                    style={{
                      fontSize: "32px",
                      color: "#fff",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      textShadow: "2px 2px 10px rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    <strong>{totalAdsViewed}</strong>
                  </p>

                  {/* Arrow Icon */}
                </div>

                {/* Expandable Window Animation */}
                <div
                  className="window-effect"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    background: "#333",
                    borderRadius: "20px",
                    transition: "transform 0.4s ease-in-out",
                    transform: "scaleY(0)",
                    transformOrigin: "top",
                    overflow: "hidden",
                    zIndex: 1,
                  }}
                ></div>
              </div>

              {/* Remaining Ads Today Card */}
              <div
                className="card mt-5 p-3"
                style={{
                  width: "300px",
                  background: "linear-gradient(45deg, #ff7e5f, #feb47b)",
                  borderRadius: "20px",
                  boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
                  color: "white",
                  overflow: "hidden",
                  position: "relative",
                  textAlign: "center",
                  transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background 0.3s ease",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0 15px 35px rgba(0, 0, 0, 0.3)";
                  e.currentTarget.style.background =
                    "linear-gradient(45deg, #6a11cb, #2575fc)"; // Color change on hover
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0 10px 25px rgba(0, 0, 0, 0.2)";
                  e.currentTarget.style.background =
                    "linear-gradient(45deg, #ff7e5f, #feb47b)"; // Revert color
                }}
              >
                {/* Glowing Effect */}
                <div
                  style={{
                    position: "absolute",
                    top: "-20px",
                    left: "-20px",
                    width: "100%",
                    height: "100%",
                    background:
                      "radial-gradient(circle, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
                    filter: "blur(40px)",
                    zIndex: 1,
                    animation: "glow 1.5s infinite",
                  }}
                ></div>

                {/* Main Content */}
                <div style={{ position: "relative", zIndex: 2 }}>
                  <label
                    style={{
                      fontSize: "22px",
                      textAlign: "center",
                      display: "block",
                      color: "#fff",
                      marginBottom: "10px",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                  >
                    <FaAd
                      className="icon-animation"
                      style={{
                        fontSize: "28px",
                        color: "#ffffff",
                        marginRight: "8px",
                      }}
                    />{" "}
                    <strong>Remainig Ads</strong>
                  </label>
                  <p
                    style={{
                      fontSize: "32px",
                      color: "#fff",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      textShadow: "2px 2px 10px rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    <strong>{remainingAds}</strong>
                  </p>

                  {/* Arrow Icon */}
                </div>

                {/* Expandable Window Animation */}
                <div
                  className="window-effect"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    background: "#333",
                    borderRadius: "20px",
                    transition: "transform 0.4s ease-in-out",
                    transform: "scaleY(0)",
                    transformOrigin: "top",
                    overflow: "hidden",
                    zIndex: 1,
                  }}
                ></div>
              </div>

              {/* Total Team Card */}
              <div
                className="card mt-5 p-3"
                style={{
                  width: "300px",
                  background: "linear-gradient(45deg, #ff7e5f, #feb47b)",
                  borderRadius: "20px",
                  boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
                  color: "white",
                  overflow: "hidden",
                  position: "relative",
                  textAlign: "center",
                  transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background 0.3s ease",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0 15px 35px rgba(0, 0, 0, 0.3)";
                  e.currentTarget.style.background =
                    "linear-gradient(45deg, #6a11cb, #2575fc)"; // Color change on hover
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0 10px 25px rgba(0, 0, 0, 0.2)";
                  e.currentTarget.style.background =
                    "linear-gradient(45deg, #ff7e5f, #feb47b)"; // Revert color
                }}
              >
                {/* Glowing Effect */}
                <div
                  style={{
                    position: "absolute",
                    top: "-20px",
                    left: "-20px",
                    width: "100%",
                    height: "100%",
                    background:
                      "radial-gradient(circle, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
                    filter: "blur(40px)",
                    zIndex: 1,
                    animation: "glow 1.5s infinite",
                  }}
                ></div>

                {/* Main Content */}
                <div style={{ position: "relative", zIndex: 2 }}>
                  <label
                    style={{
                      fontSize: "22px",
                      textAlign: "center",
                      display: "block",
                      color: "#fff",
                      marginBottom: "10px",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      fontWeight: "bold",
                    }}
                  >
                    <FaUsers
                      className="icon-animation"
                      style={{
                        fontSize: "28px",
                        color: "#ffffff",
                        marginRight: "8px",
                      }}
                    />{" "}
                    <strong>Teams</strong>
                  </label>
                  <p
                    style={{
                      fontSize: "32px",
                      color: "#fff",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      textShadow: "2px 2px 10px rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    <strong>{totalReferrals}</strong>
                  </p>

                  {/* Arrow Icon */}
                </div>

                {/* Expandable Window Animation */}
                <div
                  className="window-effect"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    background: "#333",
                    borderRadius: "20px",
                    transition: "transform 0.4s ease-in-out",
                    transform: "scaleY(0)",
                    transformOrigin: "top",
                    overflow: "hidden",
                    zIndex: 1,
                  }}
                ></div>
              </div>
            </div>

            <div
              className="p-3 card mt-5"
              style={{
                background: "linear-gradient(145deg, #282828, #1c1c1c)",
                width: "350px",
                height: "280px",
                borderRadius: "20px",
                boxShadow: "0 10px 20px rgba(0, 0, 0, 0.5)",
                marginLeft: "36px",
                position: "relative",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {/* Glowing Pulse Animation */}
              <div
                className="pulse-effect"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "200px",
                  height: "200px",
                  background: "rgba(0, 123, 255, 0.2)",
                  borderRadius: "50%",
                  animation: "pulse 3s infinite",
                  zIndex: 1,
                }}
              ></div>

              {/* Content */}
              <div>
                {/* Top Decoration */}
                <div
                  style={{
                    position: "absolute",
                    top: "-40px",
                    right: "-40px",
                    width: "100px",
                    height: "100px",
                    background: "linear-gradient(145deg, #007bff, #0056b3)",
                    borderRadius: "50%",
                    boxShadow: "0 5px 15px rgba(0, 123, 255, 0.6)",
                  }}
                ></div>

                {/* Content */}
                <div
                  className="referral-code-container text-center"
                  style={{ zIndex: 2 }}
                >
                  <h3
                    style={{
                      color: "#fff",
                      fontSize: "22px",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      fontWeight: "bold",
                      marginBottom: "20px",
                    }}
                  >
                    Invite Friends, Earn Money
                  </h3>
                  <h2
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginBottom: "15px",
                      letterSpacing: "1px",
                      textShadow: "0 2px 5px rgba(0, 0, 0, 0.6)",
                    }}
                  >
                    Your Referral Link
                  </h2>
                  <div
                    className="input-group"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      style={{
                        height: "50px",
                        fontSize: "16px",
                        width: "90%",
                        border: "none",
                        borderRadius: "10px",
                        boxShadow: "inset 0 3px 6px rgba(0, 0, 0, 0.3)",
                        textAlign: "center",
                      }}
                      value={`${referralLink}`}
                      readOnly
                    />
                    <button
                      className="btn btn-primary"
                      style={{
                        width: "90%",
                        height: "45px",
                        fontSize: "16px",
                        backgroundColor: "#007bff",
                        border: "none",
                        borderRadius: "10px",
                        color: "#fff",
                        boxShadow: "0 4px 10px rgba(0, 123, 255, 0.4)",
                        transition: "transform 0.3s ease",
                      }}
                      onClick={handleCopyreferralLink}
                      onMouseEnter={(e) =>
                        (e.target.style.transform = "scale(1.05)")
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.transform = "scale(1)")
                      }
                    >
                      {copyTooltipText}
                    </button>
                  </div>
                </div>
              </div>

              {/* Keyframe Animation */}
              <style>
                {`
  @keyframes pulse {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0.6;
    }
    50% {
      transform: translate(-50%, -50%) scale(1.2);
      opacity: 0.4;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0.6;
    }
  }
`}
              </style>
            </div>
            {/*Salary Status*/}
            <div
              className="card mt-5 p-3"
              style={{
                maxWidth: "400px",
                margin: "auto",
                marginBottom: "40px",
              }}
            >
              <h2 className="bg-dark">Claim Salary</h2>
              {error && <p className="text-danger">{error}</p>}
              {isEligible ? (
                <>
                  <p>
                    Monthly Salary: {userData?.monthlySalary} {displayCurrency}
                  </p>

                  <p>Required Active Referrals: {userData?.MinActiveRef}</p>
                  <p>Active Referrals: {activeUsers}</p>

                  {/* Show Claim Salary button only if not claimed */}
                  {!isClaimed ? (
                    <button onClick={handleClaim} className="btn btn-primary">
                      Claim Salary
                    </button>
                  ) : (
                    <p>Salary claimed successfully.</p>
                  )}
                </>
              ) : (
                <p>Not eligible for salary. Please check your details.</p>
              )}
              <div className="container my-5">
                <div className="card shadow-lg p-3">
                  <h2 className="bg-dark text-center mb-4">Salary Details</h2>

                  {userData ? (
                    <>
                      <div className="table-responsive mt-4">
                        <table className="table table-bordered table-hover">
                          <thead className="thead-dark">
                            <tr>
                              <th>#</th>
                              <th>Email</th>

                              <th>Claim Amount</th>
                              <th>Claim Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {salaryClaims.length > 0 ? (
                              salaryClaims.map((claim, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{userdata.email}</td>
                                  <td>
                                    {claim.claimAmount} {displayCurrency}
                                  </td>
                                  <td>
                                    {new Date(
                                      claim.claimStartTime
                                    ).toLocaleDateString()}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="4" className="text-center">
                                  No salary claims found.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>
              </div>
            </div>

            {/*all Refferak*/}
          </>
        ) : (
          <>
            <div className="d-flex flex-wrap justify-content-around">
              <div className="card mt-5 p-3" style={{ width: "300px" }}>
                <div className="bg-white">
                  <label style={{ fontSize: "20px", textAlign: "center" }}>
                    <strong>Package</strong>
                  </label>
                  <p style={{ fontSize: "30px", color: "black" }}>
                    No Membership Purchase
                  </p>
                </div>
              </div>

              <div className="card mt-5 p-3" style={{ width: "300px" }}>
                <div className="bg-white">
                  <label style={{ fontSize: "20px", textAlign: "center" }}>
                    <strong>Status</strong>
                  </label>
                  <p style={{ fontSize: "30px", color: "black" }}></p>
                </div>
              </div>
              <div className="card mt-5 p-3" style={{ width: "300px" }}>
                <div className="bg-white">
                  <label style={{ fontSize: "20px", textAlign: "center" }}>
                    <strong>Available Earnings</strong>
                  </label>
                  <p style={{ fontSize: "30px", color: "black" }}>
                    <strong>
                      {/* Check if loading is true, then display loading message */}
                      {loading ? (
                        <span>Loading...</span>
                      ) : (
                        <>
                          {/* Check if earnings exist, then display total earnings */}
                          {earnings ? earnings.earnings : "0"} {displayCurrency}
                        </>
                      )}
                    </strong>
                  </p>
                </div>
              </div>
              <div className="card mt-5 p-3" style={{ width: "300px" }}>
                <div className="bg-white">
                  <label style={{ fontSize: "20px", textAlign: "center" }}>
                    <strong>TotalEarnings</strong>
                  </label>
                  <p style={{ fontSize: "30px", color: "black" }}>
                    <strong>
                      {loading ? (
                        <span>Loading...</span>
                      ) : (
                        <>
                          {/* Check if earnings exist, then display total earnings */}
                          {earnings ? earnings.totalEarnings : "0"}{" "}
                          {/* Check if currency exists and then display corresponding sign */}
                          {displayCurrency}
                        </>
                      )}
                    </strong>
                  </p>
                </div>
              </div>
              <div className="card mt-5 p-3" style={{ width: "300px" }}>
                <div className="bg-white">
                  <label style={{ fontSize: "20px", textAlign: "center" }}>
                    <strong>Total Team</strong>
                  </label>
                  <p style={{ fontSize: "30px", color: "black" }}>
                    <strong>{totalReferrals}</strong>
                  </p>
                </div>
              </div>
              <div className="card mt-5 p-3" style={{ width: "300px" }}>
                <div className="bg-white">
                  <label style={{ fontSize: "20px", textAlign: "center" }}>
                    <strong>Active Members</strong>
                  </label>
                  <p style={{ fontSize: "30px", color: "black" }}>
                    <strong>{activeUsers}</strong>
                  </p>
                </div>
              </div>
              <div className="card mt-5 p-3" style={{ width: "300px" }}>
                <div className="bg-white">
                  <label style={{ fontSize: "20px", textAlign: "center" }}>
                    <strong>Inactive Members</strong>
                  </label>
                  <p style={{ fontSize: "30px", color: "black" }}>
                    <strong>{inactiveUsers}</strong>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="p-1 mt-5 card "
              style={{
                backgroundColor: "black",
                width: "320px",
                height: "250px",
                marginLeft: "36px",
              }}
            >
              <div
                className="referral-code-container mt-5 mb-5"
                style={{ overflow: "hidden" }}
              >
                <h3
                  className="mb-5"
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "25px",
                  }}
                >
                  Invite Friend Earn Money
                </h3>
                <h1 style={{ color: "white" }}>RefferalLink</h1>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    style={{ height: "50px", fontSize: "20px" }}
                    value={`${referralLink}`}
                    readOnly
                  />

                  <button
                    className="btn "
                    style={{ marginLeft: "10px", height: "50px" }}
                    type="button"
                    onClick={handleCopyreferralLink}
                  >
                    {copyTooltipText}
                  </button>
                </div>
              </div>
            </div>

            <div className="container mt-5">
              <div className="row justify-content-center">
                <div className="col-md-10 mb-3">
                  <div className="card p-4 shadow-sm">
                    <h3>Total Referrals</h3>
                    {referrals.length > 0 ? (
                      <table className="table table-striped mt-3">
                        <thead>
                          <tr>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Package Name</th>
                            <th>Package Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {referrals.map((referral, index) => (
                            <tr key={index}>
                              <td>{referral.username}</td>
                              <td>{referral.email}</td>
                              <td>
                                {referral.packageName
                                  ? referral.packageName
                                  : "No membership bought"}
                              </td>
                              <td
                                style={{
                                  color:
                                    referral.packageStatus === "Active"
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {referral.packageStatus
                                  ? referral.packageStatus
                                  : "No membership bought"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className="text-center mt-3">
                        No referrals available
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="container my-5">
              <div className="row justify-content-center">
                <div className="col-12 col-md-10">
                  <div className="cards shadow-sm">
                    <div className="card-header bg-primary text-white text-center py-3">
                      <h2>All Withdrawals</h2>
                    </div>
                    <div className="card-body">
                      <div
                        className="table-responsive text-dark"
                        style={{ fontSize: "30px" }}
                      >
                        no withdrawal
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="alert alert-secondary text-center" role="alert">
        <p className="welcome-text">
          <strong className="heading">
            Welcome to <span className="earn-tube">EᵃʳⁿTᵘᵇᵉ💲!</span>
          </strong>
          Here, you have the opportunity to earn unlimited money and build a
          better financial future. With a variety of exciting ways to earn,{" "}
          <strong>EarnTube</strong> is the ultimate platform to start making
          money online. Whether you're interested in watching ads, building a
          team, or taking advantage of generous bonuses, we've got it all. Plus,
          we offer a special <span className="highlight">Sunday Offer</span>{" "}
          that gives you even more opportunities to earn, making this platform
          perfect for both short-term gains and long-term financial success.
        </p>

        <h2 className="section-heading">How You Can Earn on EarnTube:</h2>
        <section className="earning-methods">
          <h3 className="method-heading">
            1. Short-Term Tasks for Quick Rewards
          </h3>
          <p className="method-description">
            Looking for a quick way to earn money? Our{" "}
            <strong>Short-Term Tasks</strong> offer a fast and effective way to
            make money by completing simple activities like watching short ads
            or engaging in small tasks. These tasks provide{" "}
            <strong>instant payouts</strong>, allowing you to earn whenever you
            have a few free minutes.
          </p>

          <h3 className="method-heading">
            2. Long-Term Earnings through Packages
          </h3>
          <p className="method-description">
            For those who prefer a more consistent income stream,{" "}
            <strong>Long-Term Packages</strong> are available. By choosing one
            of our long-term earning packages, you commit to earning money over
            time by completing tasks that accumulate. This option is great for
            setting up a steady and reliable source of income.
          </p>

          <h3 className="method-heading">
            3. Daily Tasks for Consistent Earnings
          </h3>
          <p className="method-description">
            Stay active and earn even more with our <strong>Daily Tasks</strong>
            . From watching videos to rating ads, our task system ensures
            constant engagement and earnings. Complete more tasks, unlock
            rewards, and increase your earning potential!
          </p>

          <h3 className="method-heading">4. Lucky Spin for Extra Rewards</h3>
          <p className="method-description">
            Feeling lucky? <strong>EarnTube</strong> offers an exciting{" "}
            <strong>Lucky Spin</strong> feature where you can win amazing
            rewards like bonus earnings, discounts on packages, and other
            surprises.
          </p>
        </section>

        <h2 className="section-heading">Deposits and Withdrawals:</h2>
        <section className="deposits-withdrawals">
          <h3 className="method-heading">Deposits</h3>
          <p className="method-description">
            Start earning by depositing into your <strong>EarnTube</strong>{" "}
            account using preferred methods like bank transfers or digital
            wallets. It’s smooth and secure.
          </p>

          <h3 className="method-heading">Withdrawals</h3>
          <p className="method-description">
            Request a withdrawal anytime! Our process is fast, reliable, and
            ensures you can access your funds promptly using your preferred
            method.
          </p>
        </section>

        <p className="closing-text">
          With <strong>EarnTube</strong>, earning is simple, fun, and engaging.
          Start your journey towards financial freedom today!
        </p>
      </div>
    </Layout>
  );
};

export default UserDashboard;
