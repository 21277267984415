import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Layout from "./../../Componet/Layout/Layout";
import { useAuth } from "../../Context/auth";
import logo from "../../Assets/sitelogo.png";
import backgroundImage from "../../Assets/20255.jpg";

import "../../Styles/Membership.css";
import {
  FaBoxOpen,
  FaCheckCircle,
  FaDatabase,
  FaExpand,
  FaProductHunt,
} from "react-icons/fa";

const Membership = () => {
  const [auth] = useAuth();
  const [membershipDetails, setMembershipDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [earnings, setEarnings] = useState(null);
  const [memberships, setMemberships] = useState([]);
  const [remainingTime, setRemainingTime] = useState({});
  const timerRef = useRef(null);
  const currencySymbol = () => {
    if (!earnings?.currency || earnings.currency === "") {
      return "Rs"; // Default to Rs if currency is null, undefined, or empty
    } else if (earnings.currency === "PKR") {
      return "Rs";
    } else if (earnings.currency === "USD") {
      return "$";
    } else {
      return ""; // Default case for unsupported currencies
    }
  };

  const displayCurrency = currencySymbol();

  const getStatusClass = (status) => {
    // Determine the color for status
    if (status === "active") {
      return "active-status"; // Green
    } else if (status === "expired") {
      return "expired-status"; // Red
    }
    return "";
  };

  useEffect(() => {
    // Fetch all memberships data when component mounts
    const fetchMemberships = async () => {
      try {
        const response = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/purchase/user/latest-membership"
        );

        // Sort memberships by purchaseDate in descending order (latest first)
        const sortedMemberships = response.data.memberships.sort((a, b) => {
          return new Date(b.purchaseDate) - new Date(a.purchaseDate);
        });

        setMemberships(sortedMemberships);
      } catch (err) {
        setError("Failed to load memberships");
      } finally {
        setLoading(false);
      }
    };

    fetchMemberships();
  }, []);

  useEffect(() => {
    const fetchUserEarnings = async () => {
      try {
        const response = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/users/earnings",
          {}
        );
        setEarnings(response.data);
      } catch (err) {
        setError("Failed to fetch earnings.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserEarnings();
  }, []);

  useEffect(() => {
    const fetchMembershipDetails = async () => {
      try {
        const response = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/purchase/membership"
        );
        setMembershipDetails(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchMembershipDetails();
  }, []);

  useEffect(() => {
    if (membershipDetails && membershipDetails.packageStatus === "Active") {
      const intervalId = setInterval(() => {
        const now = new Date().getTime();
        const expiryDate = new Date(membershipDetails.expiryDate).getTime();
        const distance = expiryDate - now;

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setRemainingTime({ days, hours, minutes, seconds });

        if (distance < 0) {
          clearInterval(intervalId);
          setRemainingTime({});
        }
      }, 1000);

      return () => clearInterval(intervalId);
    } else {
      setRemainingTime({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    }
  }, [membershipDetails]);

  useEffect(() => {
    const timer = timerRef.current;
    let isDragging = false;

    const onMouseMove = (e) => {
      if (!isDragging) return;
      timer.style.left = `${e.clientX - timer.offsetWidth / 2}px`;
      timer.style.top = `${e.clientY - timer.offsetHeight / 2}px`;
    };

    const onMouseDown = () => {
      isDragging = true;
    };

    const onMouseUp = () => {
      isDragging = false;
    };

    if (timer) {
      timer.addEventListener("mousedown", onMouseDown);
      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mouseup", onMouseUp);
    }

    return () => {
      if (timer) {
        timer.removeEventListener("mousedown", onMouseDown);
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mouseup", onMouseUp);
      }
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }
  const formatAmount = (amount) => {
    return parseFloat(amount)
      .toFixed(4)
      .replace(/(\.0+|(?<=\.\d)0+)$/, "");
  };
  return (
    <Layout title={"User-Membership EᵃʳⁿTᵘᵇᵉ💲"}>
      <div
        className="dashboard-container"
        style={{
          padding: "40px",
          borderRadius: "20px",
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.5)",
          textAlign: "center",
          maxWidth: "750px",
          margin: "100px auto",
          position: "relative",
          overflow: "hidden",
          color: "white",
          backgroundImage: `url(${backgroundImage})`, // Correctly imported image
          backgroundSize: "cover", // Adjusted to fit the card properly
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* Main Content */}
        <div style={{ position: "relative", zIndex: 2 }}>
          <h1
            style={{
              fontSize: "42px",
              fontWeight: "700",
              textTransform: "uppercase",
              color: "black",
              marginBottom: "20px",
            }}
          >
            MEMBERSHIP
          </h1>

          <div className="user-info" style={{ marginTop: "30px" }}>
            <img
              src={logo}
              alt="Dashboard Logo"
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                border: "4px solid #00ffff",
                boxShadow: "0px 5px 20px rgba(0, 255, 255, 0.5)",
              }}
            />

            <div
              className="info-box"
              style={{
                marginTop: "20px",
                padding: "20px",
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: "15px",
                boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  marginBottom: "10px",
                  fontWeight: "600",
                  color: "white",
                }}
              >
                👨 {auth?.user?.username}
              </p>
              <p style={{ fontSize: "18px", marginBottom: "5px" }}>
                <strong
                  style={{
                    color: "#000",

                    padding: "10px",
                  }}
                >
                  Earnings:
                </strong>{" "}
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span
                    style={{
                      color: "#fff",

                      padding: "10px",
                    }}
                  >
                    {formatAmount(earnings ? earnings.earnings : "0")}{" "}
                    {displayCurrency}
                  </span>
                )}
              </p>
              <p style={{ fontSize: "18px" }}>
                <strong
                  style={{
                    color: "#fff",

                    padding: "10px",
                  }}
                >
                  Total Earnings:
                </strong>{" "}
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span
                    style={{
                      color: "#fff",

                      padding: "10px",
                    }}
                  >
                    {formatAmount(earnings ? earnings.totalEarnings : "0")}{" "}
                    {displayCurrency}
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <h1 className="mt-5 p-5 text-center bg-dark">Membership Details</h1>
      {membershipDetails ? (
        <div className="d-flex flex-wrap justify-content-around">
          <div
            className="card mt-5 p-3"
            style={{
              width: "300px",
              background: "linear-gradient(45deg, #ff7e5f, #feb47b)",
              borderRadius: "20px",
              boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
              color: "white",
              overflow: "hidden",
              position: "relative",
              textAlign: "center",
              transition:
                "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background 0.3s ease",
              cursor: "pointer",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "scale(1.05)";
              e.currentTarget.style.boxShadow =
                "0 15px 35px rgba(0, 0, 0, 0.3)";
              e.currentTarget.style.background =
                "linear-gradient(45deg, #6a11cb, #2575fc)"; // Color change on hover
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "scale(1)";
              e.currentTarget.style.boxShadow =
                "0 10px 25px rgba(0, 0, 0, 0.2)";
              e.currentTarget.style.background =
                "linear-gradient(45deg, #ff7e5f, #feb47b)"; // Revert color
            }}
          >
            {/* Glowing Effect */}
            <div
              style={{
                position: "absolute",
                top: "-20px",
                left: "-20px",
                width: "100%",
                height: "100%",
                background:
                  "radial-gradient(circle, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
                filter: "blur(40px)",
                zIndex: 1,
                animation: "glow 1.5s infinite",
              }}
            ></div>

            {/* Main Content */}
            <div style={{ position: "relative", zIndex: 2 }}>
              <label
                style={{
                  fontSize: "22px",
                  textAlign: "center",
                  display: "block",
                  color: "#fff",
                  marginBottom: "10px",
                  textTransform: "uppercase",
                  letterSpacing: "1px",
                  fontWeight: "bold",
                }}
              >
                <FaBoxOpen
                  className="icon-animation"
                  style={{
                    fontSize: "28px",
                    color: "#ffffff",
                    marginRight: "8px",
                  }}
                />{" "}
                <strong>Package</strong>
              </label>
              <p
                style={{
                  fontSize: "32px",
                  color: "#fff",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  textShadow: "2px 2px 10px rgba(0, 0, 0, 0.4)",
                }}
              >
                <strong>{membershipDetails.packageName}</strong>
              </p>

              {/* Arrow Icon */}
            </div>

            {/* Expandable Window Animation */}
            <div
              className="window-effect"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                background: "#333",
                borderRadius: "20px",
                transition: "transform 0.4s ease-in-out",
                transform: "scaleY(0)",
                transformOrigin: "top",
                overflow: "hidden",
                zIndex: 1,
              }}
            ></div>
          </div>

          <div
            className="card mt-5 p-3"
            style={{
              width: "300px",
              background: "linear-gradient(45deg, #ff7e5f, #feb47b)",
              borderRadius: "20px",
              boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
              color: "white",
              overflow: "hidden",
              position: "relative",
              textAlign: "center",
              transition:
                "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background 0.3s ease",
              cursor: "pointer",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "scale(1.05)";
              e.currentTarget.style.boxShadow =
                "0 15px 35px rgba(0, 0, 0, 0.3)";
              e.currentTarget.style.background =
                "linear-gradient(45deg, #6a11cb, #2575fc)"; // Color change on hover
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "scale(1)";
              e.currentTarget.style.boxShadow =
                "0 10px 25px rgba(0, 0, 0, 0.2)";
              e.currentTarget.style.background =
                "linear-gradient(45deg, #ff7e5f, #feb47b)"; // Revert color
            }}
          >
            {/* Glowing Effect */}
            <div
              style={{
                position: "absolute",
                top: "-20px",
                left: "-20px",
                width: "100%",
                height: "100%",
                background:
                  "radial-gradient(circle, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
                filter: "blur(40px)",
                zIndex: 1,
                animation: "glow 1.5s infinite",
              }}
            ></div>

            {/* Main Content */}
            <div style={{ position: "relative", zIndex: 2 }}>
              <label
                style={{
                  fontSize: "22px",
                  textAlign: "center",
                  display: "block",
                  color: "#fff",
                  marginBottom: "10px",
                  textTransform: "uppercase",
                  letterSpacing: "1px",
                  fontWeight: "bold",
                }}
              >
                <FaCheckCircle
                  className="icon-animation"
                  style={{
                    fontSize: "28px",
                    color: "#ffffff",
                    marginRight: "8px",
                  }}
                />{" "}
                <strong>Status</strong>
              </label>
              <p
                style={{
                  fontSize: "32px",
                  color: "#fff",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  textShadow: "2px 2px 10px rgba(0, 0, 0, 0.4)",
                }}
              >
                <strong>{membershipDetails.packageStatus}</strong>
              </p>

              {/* Arrow Icon */}
            </div>

            {/* Expandable Window Animation */}
            <div
              className="window-effect"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                background: "#333",
                borderRadius: "20px",
                transition: "transform 0.4s ease-in-out",
                transform: "scaleY(0)",
                transformOrigin: "top",
                overflow: "hidden",
                zIndex: 1,
              }}
            ></div>
          </div>

          <div
            className="card mt-5 p-3"
            style={{
              width: "300px",
              background: "linear-gradient(45deg, #ff7e5f, #feb47b)",
              borderRadius: "20px",
              boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
              color: "white",
              overflow: "hidden",
              position: "relative",
              textAlign: "center",
              transition:
                "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background 0.3s ease",
              cursor: "pointer",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "scale(1.05)";
              e.currentTarget.style.boxShadow =
                "0 15px 35px rgba(0, 0, 0, 0.3)";
              e.currentTarget.style.background =
                "linear-gradient(45deg, #6a11cb, #2575fc)"; // Color change on hover
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "scale(1)";
              e.currentTarget.style.boxShadow =
                "0 10px 25px rgba(0, 0, 0, 0.2)";
              e.currentTarget.style.background =
                "linear-gradient(45deg, #ff7e5f, #feb47b)"; // Revert color
            }}
          >
            {/* Glowing Effect */}
            <div
              style={{
                position: "absolute",
                top: "-20px",
                left: "-20px",
                width: "100%",
                height: "100%",
                background:
                  "radial-gradient(circle, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
                filter: "blur(40px)",
                zIndex: 1,
                animation: "glow 1.5s infinite",
              }}
            ></div>

            {/* Main Content */}
            <div style={{ position: "relative", zIndex: 2 }}>
              <label
                style={{
                  fontSize: "22px",
                  textAlign: "center",
                  display: "block",
                  color: "#fff",
                  marginBottom: "10px",
                  textTransform: "uppercase",
                  letterSpacing: "1px",
                  fontWeight: "bold",
                }}
              >
                <FaProductHunt
                  className="icon-animation"
                  style={{
                    fontSize: "28px",
                    color: "#ffffff",
                    marginRight: "8px",
                  }}
                />{" "}
                <strong>Purchase</strong>
              </label>
              <p
                style={{
                  fontSize: "32px",
                  color: "#fff",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  textShadow: "2px 2px 10px rgba(0, 0, 0, 0.4)",
                }}
              >
                <strong>
                  {new Date(
                    membershipDetails.purchaseDate
                  ).toLocaleDateString()}
                </strong>
              </p>

              {/* Arrow Icon */}
            </div>

            {/* Expandable Window Animation */}
            <div
              className="window-effect"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                background: "#333",
                borderRadius: "20px",
                transition: "transform 0.4s ease-in-out",
                transform: "scaleY(0)",
                transformOrigin: "top",
                overflow: "hidden",
                zIndex: 1,
              }}
            ></div>
          </div>

          <div
            className="card mt-5 p-3"
            style={{
              width: "300px",
              background: "linear-gradient(45deg, #ff7e5f, #feb47b)",
              borderRadius: "20px",
              boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
              color: "white",
              overflow: "hidden",
              position: "relative",
              textAlign: "center",
              transition:
                "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background 0.3s ease",
              cursor: "pointer",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "scale(1.05)";
              e.currentTarget.style.boxShadow =
                "0 15px 35px rgba(0, 0, 0, 0.3)";
              e.currentTarget.style.background =
                "linear-gradient(45deg, #6a11cb, #2575fc)"; // Color change on hover
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "scale(1)";
              e.currentTarget.style.boxShadow =
                "0 10px 25px rgba(0, 0, 0, 0.2)";
              e.currentTarget.style.background =
                "linear-gradient(45deg, #ff7e5f, #feb47b)"; // Revert color
            }}
          >
            {/* Glowing Effect */}
            <div
              style={{
                position: "absolute",
                top: "-20px",
                left: "-20px",
                width: "100%",
                height: "100%",
                background:
                  "radial-gradient(circle, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
                filter: "blur(40px)",
                zIndex: 1,
                animation: "glow 1.5s infinite",
              }}
            ></div>

            {/* Main Content */}
            <div style={{ position: "relative", zIndex: 2 }}>
              <label
                style={{
                  fontSize: "22px",
                  textAlign: "center",
                  display: "block",
                  color: "#fff",
                  marginBottom: "10px",
                  textTransform: "uppercase",
                  letterSpacing: "1px",
                  fontWeight: "bold",
                }}
              >
                <FaDatabase
                  className="icon-animation"
                  style={{
                    fontSize: "28px",
                    color: "#ffffff",
                    marginRight: "8px",
                  }}
                />{" "}
                <strong>Expired</strong>
              </label>
              <p
                style={{
                  fontSize: "32px",
                  color: "#fff",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  textShadow: "2px 2px 10px rgba(0, 0, 0, 0.4)",
                }}
              >
                <strong>
                  {new Date(membershipDetails.expiryDate).toLocaleDateString()}
                </strong>
              </p>

              {/* Arrow Icon */}
            </div>

            {/* Expandable Window Animation */}
            <div
              className="window-effect"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                background: "#333",
                borderRadius: "20px",
                transition: "transform 0.4s ease-in-out",
                transform: "scaleY(0)",
                transformOrigin: "top",
                overflow: "hidden",
                zIndex: 1,
              }}
            ></div>
          </div>
        </div>
      ) : (
        <>
          <p>No membership purchased.</p>
        </>
      )}

      <div ref={timerRef} className="timer-container">
        <p>Remaining Time</p>
        <div className="timer">
          {remainingTime.days !== undefined ? (
            <>
              {remainingTime.days}d {remainingTime.hours}h{" "}
              {remainingTime.minutes}m {remainingTime.seconds}s
            </>
          ) : (
            "00d 00h 00m 00s"
          )}
        </div>
      </div>
      <div>
        <h2>Your Memberships</h2>
        {memberships.length === 0 ? (
          <p>No memberships found</p>
        ) : (
          <table className="membership-table">
            <thead>
              <tr>
                <th>Package Name</th>
                <th>Status</th>
                <th>Purchase Date</th>
                <th>Expiry Date</th>
                <th>Earning Rate</th>
              </tr>
            </thead>
            <tbody>
              {memberships.map((membership, index) => (
                <tr key={index}>
                  <td>{membership.packageName}</td>
                  <td className={getStatusClass(membership.packageStatus)}>
                    {membership.packageStatus}
                  </td>
                  <td>
                    {new Date(membership.purchaseDate).toLocaleDateString()}
                  </td>
                  <td>
                    {new Date(membership.expiryDate).toLocaleDateString()}
                  </td>
                  <td>{membership.earningRate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <style jsx>{`
          .membership-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          }

          .membership-table th,
          .membership-table td {
            padding: 12px;
            text-align: center;
            border: 1px solid #ddd;
          }

          .membership-table th {
            background-color: #f4f4f4;
            color: #333;
          }

          .membership-table tr:hover {
            background-color: #f1f1f1;
          }

          .active-status {
            background-color: #28a745; /* Green */
            color: white;
            padding: 5px;
            border-radius: 5px;
          }

          .expired-status {
            background-color: #dc3545; /* Red */
            color: white;
            padding: 5px;
            border-radius: 5px;
          }
        `}</style>
      </div>
      <div className="alert alert-secondary text-center" role="alert">
        <p className="welcome-text">
          <strong className="heading">
            Welcome to <span className="earn-tube">EᵃʳⁿTᵘᵇᵉ💲!</span>
          </strong>
          Here, you have the opportunity to earn unlimited money and build a
          better financial future. With a variety of exciting ways to earn,{" "}
          <strong>EarnTube</strong> is the ultimate platform to start making
          money online. Whether you're interested in watching ads, building a
          team, or taking advantage of generous bonuses, we've got it all. Plus,
          we offer a special <span className="highlight">Sunday Offer</span>{" "}
          that gives you even more opportunities to earn, making this platform
          perfect for both short-term gains and long-term financial success.
        </p>

        <h2 className="section-heading">How You Can Earn on EarnTube:</h2>
        <section className="earning-methods">
          <h3 className="method-heading">
            1. Short-Term Tasks for Quick Rewards
          </h3>
          <p className="method-description">
            Looking for a quick way to earn money? Our{" "}
            <strong>Short-Term Tasks</strong> offer a fast and effective way to
            make money by completing simple activities like watching short ads
            or engaging in small tasks. These tasks provide{" "}
            <strong>instant payouts</strong>, allowing you to earn whenever you
            have a few free minutes.
          </p>

          <h3 className="method-heading">
            2. Long-Term Earnings through Packages
          </h3>
          <p className="method-description">
            For those who prefer a more consistent income stream,{" "}
            <strong>Long-Term Packages</strong> are available. By choosing one
            of our long-term earning packages, you commit to earning money over
            time by completing tasks that accumulate. This option is great for
            setting up a steady and reliable source of income.
          </p>

          <h3 className="method-heading">
            3. Daily Tasks for Consistent Earnings
          </h3>
          <p className="method-description">
            Stay active and earn even more with our <strong>Daily Tasks</strong>
            . From watching videos to rating ads, our task system ensures
            constant engagement and earnings. Complete more tasks, unlock
            rewards, and increase your earning potential!
          </p>

          <h3 className="method-heading">4. Lucky Spin for Extra Rewards</h3>
          <p className="method-description">
            Feeling lucky? <strong>EarnTube</strong> offers an exciting{" "}
            <strong>Lucky Spin</strong> feature where you can win amazing
            rewards like bonus earnings, discounts on packages, and other
            surprises.
          </p>
        </section>

        <h2 className="section-heading">Deposits and Withdrawals:</h2>
        <section className="deposits-withdrawals">
          <h3 className="method-heading">Deposits</h3>
          <p className="method-description">
            Start earning by depositing into your <strong>EarnTube</strong>{" "}
            account using preferred methods like bank transfers or digital
            wallets. It’s smooth and secure.
          </p>

          <h3 className="method-heading">Withdrawals</h3>
          <p className="method-description">
            Request a withdrawal anytime! Our process is fast, reliable, and
            ensures you can access your funds promptly using your preferred
            method.
          </p>
        </section>

        <p className="closing-text">
          With <strong>EarnTube</strong>, earning is simple, fun, and engaging.
          Start your journey towards financial freedom today!
        </p>
      </div>
    </Layout>
  );
};

export default Membership;
