import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../Styles/Verification.css"; // Import the CSS file for styling
import { useNavigate } from "react-router-dom";

const UserLevel = () => {
  const [levels, setLevels] = useState([]); // To store fetched levels
  const [userLevel, setUserLevel] = useState(null); // To store user's current level
  const [loading, setLoading] = useState(true); // To handle loading state
  const [error, setError] = useState(null); // To handle errors
  const [unlocking, setUnlocking] = useState(false); // To handle unlocking state
  const [unlockError, setUnlockError] = useState(null); // To handle unlock errors
  const [unlockSuccess, setUnlockSuccess] = useState(null); // To handle unlock success
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLevels = async () => {
      try {
        setError(null);

        // Fetch all levels from backend
        const response = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/leveluser/get-level"
        );
        setLevels(response.data.levels); // Update levels state

        // Fetch current user's level
        const userResponse = await axios.get(
          "https://backend-code-9qn4.onrender.com/api/v1/leveluser/user-level"
        );

        if (userResponse.data.levelDetails) {
          setUserLevel(userResponse.data.levelDetails); // Update user level
        } else {
          setUserLevel(null); // Ensure user level is null if not found
        }
      } catch (err) {
        console.error("Error fetching levels or user level:", err);
        setError("Unable to fetch levels. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchLevels();
  }, []);

  // After unlocking the level
  const handleUnlockLevel = async (levelId, minActiveRef) => {
    try {
      setUnlocking(true);
      setUnlockError(null);
      setUnlockSuccess(null);

      // Fetch active referrals count
      const referralResponse = await axios.get(
        "https://backend-code-9qn4.onrender.com/api/v1/users/total-referrals"
      );

      const activeReferrals = referralResponse.data.referralDetails.filter(
        (referral) => referral.packageStatus === "Active"
      ).length;

      // Check if active referrals meet the minimum requirement
      if (activeReferrals < minActiveRef) {
        setUnlockError(
          `You need at least ${minActiveRef} active referrals to unlock this level.`
        );
        return;
      }

      // Send unlock request to backend
      const response = await axios.post(
        "https://backend-code-9qn4.onrender.com/api/v1/leveluser/unlock-level",
        { levelId }
      );

      // If the unlock is successful, update both levels and user level
      setUnlockSuccess(response.data.message);

      // Update the user level after unlocking
      setUserLevel(response.data.levelDetails);

      // Refresh the levels list if needed
      setLevels((prevLevels) =>
        prevLevels.map((level) =>
          level._id === levelId ? { ...level, status: "Unlocked" } : level
        )
      );

      navigate("/dashboard/user/useraccount");

      // Hide success message after 3 seconds
      setTimeout(() => setUnlockSuccess(null), 3000);
    } catch (err) {
      console.error("Error unlocking level:", err);
      setUnlockError(err.response?.data?.message || "Failed to unlock level.");
    } finally {
      setUnlocking(false);
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="user-level-container">
      {/* Current Active Level Section */}
      <marquee>
        <h3 style={{ color: "white" }}>
          In this level your monthly salary start from level 3 where you can
          claim your monthly salary on every month{" "}
        </h3>
      </marquee>
      <div className="current-level-section">
        {userLevel ? (
          <div className="current-level">
            <h2>Your Current Active Level</h2>
            <div className="level-card">
              <h3>Level {userLevel.levelId.Level}</h3>
              <p>
                Upgrade Commission:{" "}
                <strong>{userLevel.upgradeCommission}</strong>
              </p>
              <p>
                Monthly Salary:{" "}
                <strong>{userLevel.levelId.MonthlySalary}</strong>
              </p>
              <p>
                Minimum Amount: <strong>{userLevel.levelId.MinAmount}</strong>
              </p>
              <p>
                Minimum Active Referrals:{" "}
                <strong>{userLevel.levelId.MinActiveRef}</strong>
              </p>
              <p>
                Currency: <strong>{userLevel.levelId.currency}</strong>
              </p>
            </div>
          </div>
        ) : (
          <p>No active level found. You can unlock any level below.</p>
        )}
      </div>

      {/* Unlock Success/Failure Message */}
      {unlockSuccess && <div className="success">{unlockSuccess}</div>}
      {unlockError && <div className="error">{unlockError}</div>}

      {/* All Levels Section */}
      <h2 className="title">All Available Levels</h2>
      <div className="levels-grid">
        {levels.map((level) => (
          <div
            className="level-card"
            key={level._id}
            style={{
              border:
                userLevel && userLevel.levelId._id === level._id
                  ? "2px solid green"
                  : "",
            }}
          >
            {/* Overlay for locked levels */}
            {(!userLevel || userLevel.levelId._id !== level._id) && (
              <div className="card-overlay">
                <button
                  className="overlay-unlock-button"
                  onClick={() =>
                    handleUnlockLevel(level._id, level.MinActiveRef)
                  }
                  disabled={unlocking}
                >
                  {unlocking ? "Unlocking..." : "Unlock"}
                </button>
              </div>
            )}
            {/* Show Active Label */}
            {userLevel && userLevel.levelId._id === level._id && (
              <div className="active-status">
                <span className="active-dot"></span>{" "}
                <span className="active-text">Active</span>
              </div>
            )}
            <h3 className="level-title">Level {level.Level}</h3>
            <p>
              Upgrade Commission: <strong>{level.upgradeCommision}</strong>
            </p>
            <p>
              Monthly Salary: <strong>{level.MonthlySalary}</strong>
            </p>
            <p>
              Minimum Amount: <strong>{level.MinAmount}</strong>
            </p>
            <p>
              Minimum Active Referrals: <strong>{level.MinActiveRef}</strong>
            </p>
            <p>
              Currency: <strong>{level.currency}</strong>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserLevel;
