import React from "react";

const ComingSoon = () => {
  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h1 style={styles.heading}>Coming Soon!</h1>
        <p style={styles.subheading}>
          We are working hard to bring something amazing!
        </p>
        <p style={styles.details}>
          Stay tuned for updates. We'll be launching very soon.
        </p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: "rgba(73, 62, 62, 0.7)", // Gradient Background Color
    color: "white",
    fontFamily: "Arial, sans-serif",
    textAlign: "center",
    padding: "20px",
  },
  content: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    padding: "40px",
    borderRadius: "10px",
    maxWidth: "600px",
    width: "100%",
  },
  heading: {
    fontSize: "48px",
    marginBottom: "20px",
    fontWeight: "bold",
  },
  subheading: {
    fontSize: "20px",
    marginBottom: "10px",
  },
  details: {
    fontSize: "16px",
  },
};

export default ComingSoon;
